<template>
  <div>
    <div class="columns">
      <div class="column">
        <input-base
          rules="required"
          label="Name"
          maxlength="100"
          placeholder="Name"
          v-model="app_name"
        />
      </div>
      <div class="column">
        <input-base
          rules="required"
          label="Short Name"
          maxlength="50"
          placeholder="Short Name"
          v-model="app_shortname"
        />
      </div>
      <div class="column">
        <input-base
          rules=""
          label="Description"
          maxlength="250"
          placeholder="Description"
          v-model="app_description"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <select-base
          rules="required"
          label="Display"
          :options="options"
          v-model="app_display"
          expanded
        />
      </div>
      <div class="column">
        <input-base
          rules="required"
          label="App Scope"
          maxlength="100"
          placeholder="/"
          v-model="app_scope"
        />
      </div>
      <div class="column">
        <input-base
          rules="required"
          label="Start URL"
          maxlength="100"
          placeholder="/"
          v-model="app_starturl"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <input-color-base
          rules="required"
          label="Theme Color"
          placeholder="#124578"
          v-model="app_themecolor"
        />
      </div>
      <div class="column">
        <input-color-base
          rules="required"
          label="Background Color"
          placeholder="#124578"
          v-model="app_bgcolor"
        />
      </div>
      <div class="column">
        <input-image-base
          rules=""
          label="Icono"
          v-model="form.icon_file"
          :src="icon_url"
          @remove="() => form.icon_remove = true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
const input_computed = key => ({
  get(){ 
    if(key in this.form) return this.form[key]; 
    if(this.config && this.config.pwa && key in this.config.pwa.attrs) return this.config.pwa.attrs[key]
    return ""
  },
  set(e){
    this.$set(this.form, key, e)
  }
})
export default {
  props:{
    value:{
      type: Object,
      default: () => {}
    }
  },
  computed:{
    ...mapGetters({
      config: "entitie/config",
      icon_url: "entitie/icon_url",
    }),
    options(){
      return [
        {id:"browser", value: "Browser"},
        {id:"standalone", value: "Standalone"},
        {id:"minimal-ui", value: "Minimal UI"},
        {id:"fullscreen", value: "Fullscreen"},
      ]
    },
    app_name: input_computed("app_name"),
    app_shortname: input_computed("app_shortname"),
    app_description: input_computed("app_description"),
    app_display: input_computed("app_display"),
    app_scope: input_computed("app_scope"),
    app_starturl: input_computed("app_starturl"),
    app_themecolor: input_computed("app_themecolor"),
    app_bgcolor: input_computed("app_bgcolor"),
    form:{
      get(){
        return this.value || {}
      },
      set(e){
        this.$emit("input",e )
      }
    },
  },
};
</script>
