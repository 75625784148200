<template>
  <div>
    <title-bar :items="[{ title }]" />

    <div class="box">
      <form-observer ref="observer" @submit.prevent="onSubmit">
        <b-tabs type="is-boxed">
          <b-tab-item label="General">
            <form-general v-model="form"></form-general>
          </b-tab-item>
          <b-tab-item label="Descripciones">
            <form-otros v-model="form"></form-otros>
          </b-tab-item>
          <b-tab-item label="App">
            <form-app v-model="form"></form-app>
          </b-tab-item>
        </b-tabs>
        <div>
          <b-button
            native-type="submit"
            type="is-primary"
            icon-left="save"
            :loading="loading"
            class="mt-3 mr-3"
            >Guardar</b-button
          >
        </div>
      </form-observer>
    </div>
  </div>
</template>

<script>
import FormApp from '../components/FormApp.vue';
import FormOtros from '../components/FormOtros.vue';
import FormGeneral from '../components/FormGeneral.vue';
import { mapGetters } from 'vuex';
export default {
  components: { FormGeneral, FormOtros, FormApp },
  data() {
    return {
      form: {},
      loading: false,
    };
  },
  computed:{
    ...mapGetters({
      empresa: "entitie/empresa"
    }),
    title(){
      return this.$route.meta.title || "";
    }
  },
  created(){
    this.defaultData()
  },
  methods: {
    defaultData(){
      this.form = {...this.empresa}
      if(this.$refs.observer){
        this.$refs.observer.reset()
      }
    },
    async onSubmit() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) return;
      //
      this.loading = true;
      const row = await this.$store.dispatch(`entitie/saveRequest`, this.form)
      if(row){
        this.$store.dispatch(
          "app/setToastSuccess",
          `Acabamos de guardar sus datos.`
        );
        this.form.logo_file = null
      }
      this.loading = false;
      
    },
  },
};
</script>
