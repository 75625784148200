<template>
  <div>
    <div class="columns">
      <div class="column">
        <input-base
          rules="required|numeric|digits:11"
          label="RUC"
          maxlength="11"
          placeholder="RUC"
          v-model="form.ruc"
        />
      </div>
      <div class="column">
        <input-base
          rules="required"
          label="Nombre de negocio"
          maxlength="200"
          placeholder="Nombre de negocio"
          v-model="form.nombre_negocio"
        />
      </div>
      <div class="column">
        <input-base
          rules="required"
          label="Nombre comercial"
          maxlength="200"
          placeholder="Nombre comercial"
          v-model="form.nombre_comercial"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <input-base
          rules=""
          label="Dirección"
          maxlength="150"
          placeholder="Dirección"
          v-model="form.direccion"
        />
      </div>
      <div class="column">
        <select-search-uri
          rules=""
          label="Región / Provincia / Distrito" 
          uri="/ubigeo/select"
          v-model="form.ubigeo_id"
          expanded
        />
      </div>
      <div class="column">
        <input-base
          rules="email"
          label="E-mail"
          maxlength="100"
          placeholder="E-mail"
          v-model="form.email"
        />
      </div>
      
    </div>
    <div class="columns">
      <div class="column">
        <input-base
          rules="numeric"
          label="Celular"
          maxlength="11"
          placeholder="Celular"
          v-model="form.celular"
        />
      </div>
      <div class="column">
        <input-base
          rules="numeric"
          label="Teléfono"
          maxlength="11"
          placeholder="Teléfono"
          v-model="form.telefono"
        />
      </div>
      <div class="column">
        <input-base
          rules=""
          label="Sitio web"
          maxlength="150"
          placeholder="Sitio web"
          v-model="form.sitio_web"
        />
      </div>
    </div>
    <div class="columns">      
      <div class="column is-2">
        <input-image-base
          rules=""
          label="Logo"
          v-model="form.logo_file"
          :src="logo_url"
          @remove="() => form.logo_remove = true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props:{
    value:{
      type: Object,
      default: () => ({})
    }
  },
  computed:{
    ...mapGetters({
      logo_url: "entitie/logo_url",
    }),
    form:{
      get(){
        return this.value || {}
      },
      set(e){
        this.$emit("input",e)
      }
    },
  },
};
</script>
